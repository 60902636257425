import React from 'react'
import styled from '@emotion/styled'
import makeCarousel from 'react-reveal/makeCarousel'

const Container = styled('div')`
  max-width: 100%;
  position: relative;
  height: ${props => (props.h ? props.h : '100%')};
  width: ${props => (props.w ? props.w : '100%')};

  @media (max-width: 1280px) {
    width: 100%;
  }

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    height: 400px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    height: 325px;
  }
`
const CarouselUI = ({
  children,
  styles = {
    height: '468px',
    width: '784px',
  },
}) => {
  const { height, width } = styles
  return (
    <Container h={height} w={width}>
      {children}
    </Container>
  )
}
export default makeCarousel(CarouselUI)
