import React from 'react'
import styled from '@emotion/styled'
import Layout from '../components/layout'
import Hero from '../components/Hero/Hero'
import WhatWeDo from '../components/Hero/WhatWeDo'
import Strategy from '../components/Home/Services/Strategy'
import Design from '../components/Home/Services/Design'
import Marketing from '../components/Home/Services/Marketing'
import Development from '../components/Home/Services/Engineering'
import TeamSection from '../components/Team/Section'
import BlogSection from '../components/Blog/BlogSection'
import StickyNav from '../components/Navigation/StickyNav'
import { Helmet } from 'react-helmet'

const HeroContainer = styled('section')`
  background-color: #000;
`

export const Container = styled('div')`
  position: relative;
  padding-left: 74px;
  padding-right: 74px;
  background: ${props => (props.bgcolor ? props.bgcolor : '#000')};

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 30px;
    padding-right: 30px;
  }
`

const metaTitle =
  'Crowdlinker | Leading Digital Product & Transformation Studio'

const metaDescription =
  'Crowdlinker helps companies build transformative user experiences, find product-market fit and scale with great strategy, design, software engineering, and product marketing.'

const IndexPage = () => (
  <Layout footerCTA={true}>
    <Helmet>
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta name="twitter:title" content={metaTitle} />
      <meta name="twitter:description" content={metaDescription} />
    </Helmet>
    <StickyNav />
    <HeroContainer>
      <Container>
        <Hero />
        <WhatWeDo />
      </Container>
    </HeroContainer>
    <Strategy />
    <Design />
    <Development />
    <Marketing />
    <TeamSection />
    <BlogSection />
  </Layout>
)

export default IndexPage
