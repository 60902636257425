import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import StickyNav from '../components/Navigation/StickyNav'
import styled from '@emotion/styled'
import PostContent from '../components/Blog/PostContent'
import BlogCTA from '../components/Blog/BlogCTA'
import TagText from '../components/Blog/TagText'
import FixedSocialMediaShareButtons from '../components/Blog/FixedSocialMediaShareButtons'
import InlineSocialMediaShareButtons from '../components/Blog/InlineSocialMediaShareButtons'
import Img from 'gatsby-image'

import { Link, graphql } from 'gatsby'
import {
  Category,
  Title,
  PostInfo,
  Description,
} from '../components/Blog/Featured'

export const PostWrapper = styled('div')`
  background: #fff;
`

export const PostsContainer = styled('div')`
  background: #fff;
  max-width: 1140px;
  padding: 140px 72px 120px;
  margin: 0 auto;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 162px;
    padding: 162px 72px 90px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 84%;
    padding: 111px 0 20px;
  }
`

export const Breadcrumb = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 18px;
  line-height: 1.22;
  color: #000000;
  margin: 0 0 19px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0 0 49px;
  }

  a {
    color: #000;
    text-decoration-color: ${props => props.theme.colors.red};
  }
`

const BreadcrumbChevron = styled('span')`
  padding: 0 10px;
`

const BreadcrumbTitle = styled('span')`
  color: #6c6c6c;
`

const PostHero = styled('div')`
  display: block;
  position: relative;
`

const PostHeroImage = styled(Img)`
  width: 100%;
  max-width: 100%;
  height: auto;
`

const PostHeroContent = styled('div')`
  padding-bottom: 98px;
  padding-top: 30px;
  position: relative;
  z-index: 2;
  max-width: 472px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-bottom: 40px;
  }
`

const PostHeroImageWrapper = styled('div')`
  position: relative;
`

const ContentContainer = styled('div')`
  max-width: 785px;
  margin: 0 auto;
`

const TagsContainer = styled('div')`
  max-width: 785px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 60px;
  }
`

const TagsHeader = styled('p')`
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 1px;
  font-family: ${props => props.theme.fonts.sourceCode};
  text-transform: uppercase;
  width: 100%;
  margin-bottom: 3px;
`

const Post = props => {
  const {
    title,
    category,
    date,
    image,
    readingTime,
    description,
    content,
    tags,
    cta,
  } = props.data.contentfulBlogPost

  const renderTags = tags
    ? tags.map(tag => (
        <TagText key={tag.id} title={tag.title} slug={tag.slug} />
      ))
    : null

  const titleWithCompanyName = `${title} | Crowdlinker`

  const postContent = content ? JSON.parse(content.content) : ''

  const headerCurrentUrl = props.location.href
  const headerImageUrl = 'https:' + image.sizes.src

  return (
    <Layout>
      <Helmet>
        <title>{titleWithCompanyName}</title>
        <meta name="description" content={description} />
        <meta property="og:title" content={titleWithCompanyName} />
        <meta property="og:description" content={description} />
        <meta property="og:url" content={headerCurrentUrl} />
        <meta property="og:site_name" content="Crowdlinker" />
        <meta property="og:image" content={headerImageUrl} />
        <meta property="og:image:secure_url" content={headerImageUrl} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:title" content={titleWithCompanyName} />
        <meta name="twitter:image" content={headerImageUrl} />
      </Helmet>
      <StickyNav showScrollIndicator={true} />
      <FixedSocialMediaShareButtons />
      <PostWrapper>
        <PostsContainer>
          <Breadcrumb>
            <Link to="/blog">Blog</Link>
            <BreadcrumbChevron>></BreadcrumbChevron>
            <BreadcrumbTitle>{title}</BreadcrumbTitle>
          </Breadcrumb>
          <InlineSocialMediaShareButtons />
          <PostHero>
            <PostHeroImageWrapper>
              <PostHeroImage sizes={image.sizes} alt={image.description} />
            </PostHeroImageWrapper>
            <PostHeroContent>
              <Category>{category.title}</Category>
              <Title>{title}</Title>
              <Description>{description}</Description>
              <PostInfo>
                {date} <strong>•</strong>
                <span>{readingTime} min read</span>
              </PostInfo>
            </PostHeroContent>
          </PostHero>
          <ContentContainer>
            <PostContent content={postContent} />
          </ContentContainer>
          {tags && (
            <TagsContainer>
              <TagsHeader>Tags: </TagsHeader>
              {renderTags}
            </TagsContainer>
          )}
          <InlineSocialMediaShareButtons />
        </PostsContainer>
      </PostWrapper>
      <BlogCTA cta={cta || undefined} />
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      category {
        title
      }
      date(formatString: "MMMM DD, YYYY")
      slug
      description
      readingTime
      tags {
        title
        id
        slug
      }
      content {
        content
      }
      image {
        sizes(maxWidth: 1992, maxHeight: 1112, cropFocus: CENTER, quality: 90) {
          ...GatsbyContentfulSizes
        }
      }
      cta {
        title
        placeholder
        buttonText
        isSubscribe
        url
        image {
          title
          fluid {
            src
          }
        }
      }
    }
  }
`
