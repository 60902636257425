import React, { Component } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'

import facebookIconSVG from '../../images/social-icons/facebook.svg'
import linkedinIconSVG from '../../images/social-icons/linkedin.svg'
import twitterIconSVG from '../../images/social-icons/twitter.svg'
import linkIconSVG from '../../images/social-icons/link.svg'

import { openShareWindow, copyToClipboard } from '../../util/socialShareLinks'

const SocialButtonContainer = styled('div')`
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 10px;
  @media (min-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`

const icon = css`
  background-color: #000;
  width: 25px;
  height: 25px;
  margin-left: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  cursor: pointer;
`

const ShareText = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 12px;
  text-transform: uppercase;
  color: #565656;
  margin-right: -8px;
`

const Facebook = styled('div')``
const Linkedin = styled('div')``
const Twitter = styled('div')``
const Share = styled('div')``

const Icon = styled('img')`
  height: 13px;
  width: 13px;
`

const CopiedAlert = styled('div')`
  position: absolute;
  right: -70px;
  transition: all 0.3s;
  opacity: ${props => (props.show ? 1 : 0)};
`

class InlineSocialMediaShareButtons extends Component {
  state = {
    showCopiedAlert: false,
  }

  handleIconClick = platform => {
    const currentUrl = window.location.href
    openShareWindow(platform, currentUrl)
  }

  shareUrl = () => {
    const currentUrl = window.location.href
    copyToClipboard(currentUrl)

    this.setState({
      showCopiedAlert: true,
    })

    setTimeout(() => {
      this.setState({
        showCopiedAlert: false,
      })
    }, 1200)
  }

  render() {
    return (
      <SocialButtonContainer>
        <ShareText>Share this post:</ShareText>
        <Facebook css={icon} onClick={() => this.handleIconClick('facebook')}>
          <Icon src={facebookIconSVG} alt="facebook" />
        </Facebook>
        <Linkedin css={icon} onClick={() => this.handleIconClick('linkedin')}>
          <Icon src={linkedinIconSVG} alt="linkedin" />
        </Linkedin>
        <Twitter css={icon} onClick={() => this.handleIconClick('twitter')}>
          <Icon src={twitterIconSVG} alt="twitter" />
        </Twitter>
        <Share css={icon} onClick={this.shareUrl}>
          <Icon src={linkIconSVG} />
        </Share>
        <CopiedAlert show={this.state.showCopiedAlert} t>
          URL copied
        </CopiedAlert>
      </SocialButtonContainer>
    )
  }
}

export default InlineSocialMediaShareButtons
