import React from 'react'
import styled from '@emotion/styled'
import TeamPhotoA1 from '../../images/team/our-team-photo-a1.jpg'
import TeamPhotoA2 from '../../images/team/our-team-photo-a2.jpg'

import TeamPhotoA1Tablet from '../../images/team/our-team-photo-a1-tablet.jpg'
import TeamPhotoA2Tablet from '../../images/team/our-team-photo-a2-tablet.jpg'

import TeamPhotoB1 from '../../images/team/our-team-photo-b1.jpg'
import TeamPhotoB1Tablet from '../../images/team/our-team-photo-b1-tablet.jpg'
import TeamPhotoB2 from '../../images/team/our-team-photo-b2.jpg'
import TeamPhotoB2Tablet from '../../images/team/our-team-photo-b2-tablet.jpg'

import AboutUsPhoto from '../../images/team/about-us-photo.jpg'

import AboutUsPhotoB1 from '../../images/team/about-us-photo-b1.jpg'
import AboutUsPhotoB1Tablet from '../../images/team/about-us-photo-b1-tablet.jpg'

import AboutUsPhotoB2 from '../../images/team/about-us-photo-b2.jpg'
import AboutUsPhotoB2Tablet from '../../images/team/about-us-photo-b2-tablet.jpg'

import AboutUsPhotoB3 from '../../images/team/about-us-photo-b3.jpg'
import AboutUsPhotoB3Tablet from '../../images/team/about-us-photo-b3-tablet.jpg'

import ScrollableAnchor from 'react-scrollable-anchor'
import { SmallHeading, TeamParagraph } from '../Text'
import Fade from 'react-reveal/Fade'
import { anchors } from '../../constants'
import ImageFade from './ImageFade'

const Section = styled('div')`
  padding: 144px 0;
  width: 100%;
  background: black;

  @media (max-width: 1280px) {
    padding: 89px 0;
  }
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    padding-top: 50px;
  }
`

const Wrapper = styled('div')`
  padding: 0 168px;
  max-width: 1440px;
  margin: auto;
  display: flex;
  flex-direction: ${props => (props.reverse ? 'row-reverse' : 'row')}
  justify-content: space-between;
  position: relative;


  @media (max-width: 1280px) {
    padding: 0 10%;
    display: block;
  }

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    padding: 0;
  }
`

const SectionTwoWrapper = styled(Wrapper)`
  margin-top: 150px;
  @media (max-width: 1280px) {
    margin-top: 50px;
  }
`

const AboutUsWrapper = styled(Wrapper)`
  padding-top: 73px;
  padding-bottom: 146px;
  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    padding: 20px 0;
  }
`

const ImageContainer = styled('div')`
  width: 784px;
  max-width: 100%;
  @media (max-width: 1280px) {
    width: 100%;

    img {
      max-width: none;
      width: 100%;
    }
  }
`

const SectionTwoImageContainer = styled(ImageContainer)`
  position: absolute;
  right: 144px;

  @media (max-width: 1280px) {
    position: static;
  }
`

const TextContainer = styled('div')`
  width: 610px;
  z-index: 10;
  @media (max-width: 1280px) {
    padding: 20px;
    width: 100%;
  }
`

const SectionOneTextContainer = styled(TextContainer)`
  position: absolute;
  right: 144px;
  top: 355px;

  @media (max-width: 1280px) {
    margin-top: -75px;
    position: relative;
    top: 0;
    left: 0;
    display: block;
  }
`

const SectionTwoTextContainer = styled(TextContainer)`
  margin-top: 360px;
  margin-bottom: 68px;
  @media (max-width: 1280px) {
    margin-top: -75px;
    margin-bottom: 20px;
  }
`

const AboutUsTextContainer = styled(TextContainer)`
  position: absolute;
  right: 144px;
  top: 470px;
  padding-bottom: 50px;

  @media (max-width: 1280px) {
    position: static;
    margin-top: -75px;
  }
`

const TeamParagraphWrap = styled('div')``

const Image = styled('img')`
  max-width: 100%;
`
const AboutUsImage = styled(Image)`
  width: 100%;
  margin-bottom: -200px;

  @media (max-width: 1280px) {
    margin-bottom: -100px;
  }
`

const TeamPhotoOneImages = [
  {
    img: TeamPhotoA1,
    tablet: TeamPhotoA1Tablet,
    alt: 'Team members looking at whiteboard',
  },
  {
    img: TeamPhotoA2,
    tablet: TeamPhotoA2Tablet,
    alt: 'Designers talking on the couch',
  },
]

const TeamPhotoTwoImages = [
  {
    img: TeamPhotoB1,
    tablet: TeamPhotoB1Tablet,
    alt: 'Team member working on couch',
  },
  {
    img: TeamPhotoB2,
    tablet: TeamPhotoB2Tablet,
    alt: 'Team member working at desk',
  },
]

const AboutUsImages = [
  {
    img: AboutUsPhotoB1,
    tablet: AboutUsPhotoB1Tablet,
    alt: 'Team members playing ping pong',
  },
  {
    img: AboutUsPhotoB2,
    tablet: AboutUsPhotoB2Tablet,
    alt: 'Team meeting',
  },
  {
    img: AboutUsPhotoB3,
    tablet: AboutUsPhotoB3Tablet,
    alt: 'Team members on halloween',
  },
]

export default () => (
  <ScrollableAnchor id={anchors.team}>
    <Section>
      <Wrapper>
        <Fade bottom distance={'100px'}>
          <ImageContainer>
            <ImageFade maxTurns={100} defaultWait={5000}>
              {TeamPhotoOneImages.map((item, index) => (
                <Fade key={index}>
                  <picture>
                    <source
                      srcSet={`${item.tablet}`}
                      media="(max-width: 1280px)"
                    />
                    <Image src={item.img} alt={item.alt} />
                  </picture>
                </Fade>
              ))}
            </ImageFade>
          </ImageContainer>
        </Fade>

        <SectionOneTextContainer>
          <Fade bottom distance={'100px'} delay={400}>
            <SmallHeading>Our Team</SmallHeading>
            <TeamParagraph>
              Inclusion is a core value within Crowdlinker. Our
              multidisciplinary team of Product Managers, Designers, Developers
              and Marketers work together on your project, as one cohesive unit.
              Our success hinges on maintaining constant lines of communication
              with our clients removing all assumptions.
            </TeamParagraph>
          </Fade>
        </SectionOneTextContainer>
      </Wrapper>

      <SectionTwoWrapper reverse={true}>
        <SectionTwoImageContainer>
          <Fade bottom distance={'100px'}>
            <ImageFade maxTurns={100} defaultWait={5000}>
              {TeamPhotoTwoImages.map((item, index) => (
                <Fade key={index}>
                  <picture>
                    <source
                      srcSet={`${item.tablet}`}
                      media="(max-width: 1280px)"
                    />
                    <Image src={item.img} alt={item.alt} />
                  </picture>
                </Fade>
              ))}
            </ImageFade>
          </Fade>
        </SectionTwoImageContainer>
        <SectionTwoTextContainer>
          <Fade bottom distance={'100px'} delay={400}>
            <TeamParagraph>
              Whether we are talking about our people, our clients, or the work
              we do, we are driven by the idea of solving complex technical
              problems. We hire the best people from various cultures and
              knowledge backgrounds, not only based on where they have been, but
              also on where they are capable of going. We enable our people to
              achieve their highest potential in both personal and professional
              development.
            </TeamParagraph>
          </Fade>
        </SectionTwoTextContainer>
      </SectionTwoWrapper>

      <AboutUsImage src={AboutUsPhoto} alt="" />
      <Wrapper>
        <TextContainer>
          <Fade bottom distance={'100px'}>
            <SmallHeading>About Us</SmallHeading>
            <TeamParagraph>
              We set out to make a difference in the world by leveraging the
              power of technology. We started as a startup, acquired funding and
              built our own SaaS products in different industries. We then
              started working more closely with our customers and creating
              custom digital solutions.
            </TeamParagraph>
          </Fade>
        </TextContainer>
      </Wrapper>
      <AboutUsWrapper>
        <Fade bottom distance={'100px'} delay={400}>
          <ImageContainer>
            <ImageFade defaultWait={5000} maxTurns={100}>
              {AboutUsImages.map((item, index) => (
                <Fade key={index}>
                  <picture>
                    <source
                      srcSet={`${item.tablet}`}
                      media="(max-width: 1280px)"
                    />
                    <Image src={item.img} alt={item.alt} />
                  </picture>
                </Fade>
              ))}
            </ImageFade>
          </ImageContainer>
        </Fade>
        <AboutUsTextContainer>
          <Fade bottom distance={'100px'}>
            <TeamParagraphWrap>
              <TeamParagraph>
                Eventually, we evolved into an end-to-end digital product studio
                offering strategy, creative, technology, and product marketing
                services. Since the inception of Crowdlinker, our work has
                touched millions of users; building digital products for any
                stage of the product life-cycle.
              </TeamParagraph>
              <TeamParagraph>
                The one constant that has remained since inception is our
                relentless focus on creating captivating user experiences for
                our clients and their consumers. For us, it’s all about
                unleashing unknown opportunities and taking your idea to the
                next level.
              </TeamParagraph>
            </TeamParagraphWrap>
          </Fade>
        </AboutUsTextContainer>
      </AboutUsWrapper>
    </Section>
  </ScrollableAnchor>
)
