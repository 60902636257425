import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'gatsby'

const TagLink = styled(Link)`
  text-decoration: none;
  &.disabled {
    pointer-events: none;
    cursor: default;
  }
`

const TagNameBackground = styled('div')`
  background-color: rgba(252, 84, 76, 0.15);
  border-radius: 18px;
  width: fit-content;
  padding: 0 20px;
  height: 30px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  margin-top: 11px;
  transform: translateY(-5px);
`

const TagName = styled('p')`
  font-size: 14px;
  font-family: ${props => props.theme.fonts.sourceCode};
  letter-spacing: 1px;
  color: ${props => props.theme.colors.red};
`

const PostTile = ({ title, slug, disabled = false }) => (
  <TagLink
    to={`/tag/` + slug}
    className={disabled ? 'disabled' : null}
    state={{
      prevPath: typeof window !== 'undefined' && window.location.pathname,
    }}
  >
    <TagNameBackground>
      <TagName>{title}</TagName>
    </TagNameBackground>
  </TagLink>
)

export default PostTile
