export function openShareWindow(platform, link) {
  let shareLink
  switch (platform) {
    case 'facebook':
      shareLink = `https://www.facebook.com/sharer/sharer.php?u=${link}`
      break
    case 'linkedin':
      shareLink = `https://linkedin.com/shareArticle?url=${link}`
      break
    case 'twitter':
      shareLink = `https://twitter.com/share?url=${link}`
      break
    default:
      break
  }

  const width = 550
  const height = 550
  const left =
    window.outerWidth / 2 +
    (window.screenX || window.screenLeft || 0) -
    width / 2
  const top =
    window.outerHeight / 2 +
    (window.screenY || window.screenTop || 0) -
    height / 2

  const windowConfig = {
    height,
    width,
    left,
    top,
    location: 'no',
    toolbar: 'no',
    status: 'no',
    directories: 'no',
    menubar: 'no',
    scrollbars: 'yes',
    resizable: 'no',
    centerscreen: 'yes',
    chrome: 'yes',
  }

  window.open(
    shareLink,
    '',
    Object.keys(windowConfig)
      .map(key => `${key}=${windowConfig[key]}`)
      .join(', ')
  )
}

export function copyToClipboard(value) {
  const tempInput = document.createElement('input')
  tempInput.style = 'position: absolute; left: -1000px; top: -1000px'
  tempInput.value = value
  document.body.appendChild(tempInput)
  tempInput.select()
  document.execCommand('copy')
  document.body.removeChild(tempInput)
}
