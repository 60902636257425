import React from 'react'
import DesignImage from '../../../images/design-bg-desktop.svg'
import DesignImageMobile from '../../../images/design-bg-tablet.svg'
import Background from './Background'
import ScrollableAnchor from 'react-scrollable-anchor'

import {
  Title,
  ServiceHeadingMedium,
  ServiceHeadingSmall,
  LargeParagraph,
} from '../../Text'

import {
  TwoColumn,
  DesignImageSection,
  TextSection,
  ServicesList,
  ServiceItem,
  ImageWrapper,
  FadeSectionOne,
  FadeSectionTwo,
} from './Layout'
import { anchors } from '../../../constants'

const services = [
  'Information Architecture',
  'Responsive Wireframes',
  'Concept Development',
  'High-Fidelity Mockups',
  'Visual Design',
  'Design Ops',
  'Interaction Design',
  'Creative Art Direction',
  'User Experience Design',
  'Scalable Design Systems',
]

const primaryColor = '#4d2cd1'
const textColor = '#152034'

const Design = () => (
  <ScrollableAnchor id={anchors.design}>
    <Background background="#fff">
      <Title color={primaryColor}>design</Title>
      <TwoColumn>
        <DesignImageSection>
          <picture>
            <source
              srcSet={`${DesignImageMobile}`}
              media="(max-width: 1280px)"
            />
            <ImageWrapper src={DesignImage} alt="" />
          </picture>
        </DesignImageSection>
        <TextSection>
          <FadeSectionOne>
            <ServiceHeadingMedium color={textColor}>
              Tell the story that you want your users to experience.
            </ServiceHeadingMedium>
            <LargeParagraph color={textColor}>
              With a strategy in place, we leverage well constructed user
              personas and journey maps to empathize with your customers and
              address the pain points through great design. We use a
              human-centered design process to create products that not only
              look good, but feel wonderful to use.
            </LargeParagraph>
          </FadeSectionOne>
          <FadeSectionTwo>
            <ServiceHeadingSmall color={primaryColor}>
              services
            </ServiceHeadingSmall>
            <ServicesList>
              {services.map((item, index) => {
                return (
                  <ServiceItem key={index} color={textColor}>
                    {item}
                  </ServiceItem>
                )
              })}
            </ServicesList>
          </FadeSectionTwo>
        </TextSection>
      </TwoColumn>
    </Background>
  </ScrollableAnchor>
)

export default Design
