import React from 'react'
import axios from 'axios'
import styled from '@emotion/styled'
import { CTA, CTAText } from '../Buttons/index'
import { Container } from '../../pages/index'
import newsletterSVG from '../../images/newsletter.svg'
import { checkEmailValid } from '../../util/checkEmailValid'
import DotLoading from '../UI/DotLoading'
import OpacityOverlay from '../UI/OpacityOverlay'
import gtmEvent from '../../util/gtmEvent'

const BlogCTAWrapper = styled('div')`
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  border: 0;
  border-style: solid;
  border-bottom-width: 3px;
  border-image-source: linear-gradient(
    to right,
    #38215d,
    #c8266a 43%,
    #e579ac 66%,
    #051f33
  );
  border-image-slice: 1;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 40px 0 54px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 50px 0 30px;
    flex-direction: column;
  }
`

const BlogCTAImage = styled('img')`
  width: 125px;
  object-fit: cover;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100px;
  }
`

const BlogCTAContainer = styled('div')`
  background: #000;
  padding: 70px 59px;
  padding-right: 0;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 0px 74px;
    padding-right: 0;
    max-width: 442px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 40px 0;
    max-width: none;
  }
`

const BlogCTABox = styled('div')`
  position: unset;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    position: relative;
  }
`

const Title = styled('h3')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 28px;
  font-weight: 500;
  color: #ffffff;
  line-height: 1.35;
  max-width: 575px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    max-width: 404px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

const BlogCTAForm = styled('form')`
  display: flex;
  margin-top: 35px;
  justify-content: space-between;
  align-items: flex-end;
  width: 720px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-right: 0;
    width: 100%;
  }
`

const InputWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  flex: 1;
  margin-right: 71px;
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 379px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    margin: 0;
  }
`

const EmailInput = styled('input')`
  width: 100%;
  border: 0;
  border-bottom: 1px solid #979797;
  font-size: 24px;
  color: #c3c3c3;
  font-weight: 300;
  background: transparent;
  outline: none;
  appearance: none;
  webkit-appearance: none;
  border-radius: 0;
  flex: 1;
  font-family: ${props => props.theme.fonts.secondary};

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-bottom: 43px;
    width: 379px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    margin: 19px 0 5px;
  }
  &:focus + span {
    left: 0;
  }
`

const Underline = styled('span')`
  transition: all 0.4s;
  display: inline-block;
  bottom: 0;
  left: -110%;
  position: absolute;
  width: 100%;
  height: 1px;
  background: linear-gradient(to right, #601947, #315567);
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin-bottom: 43px;
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 39px;
  }
`

const BlogSubscribeButton = CTA.withComponent('button')

const BlogCTAButton = CTA.withComponent('a')

const BlogButtonInner = styled(CTAText)`
  padding: 0 19px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0 10px;
  }
`

const ErrorMessage = styled('p')`
  color: ${props => props.errorColor};
  position: absolute;
  transform: translateY(14px);
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    display: none;
  }
`

const MobileErrorMessage = styled('p')`
  display: none;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0;
    display: block;
    height: 24px;
    margin-bottom: 4px;
    color: ${props => props.errorColor};
    opacity: ${props => (props.error ? 1 : 0)};
  }
`

const SuccessHeader = styled('h2')`
  color: #fff;
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 24px;
  font-weight: 500;
`

const SuccessBody = styled('p')`
  color: #fff;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 20px;
  line-height: 1.25;
`

const LoadingContainer = styled('div')`
  position: absolute;
  right: 0;
  left: 0;
  transform: translateY(-38px);
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    transform: translateY(-22px);
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    transform: translateY(-10px);
  }
`

class BlogCTA extends React.Component {
  static defaultProps = {
    cta: {
      title: 'Get the latest updates from Crowdlinker',
      buttonText: 'Subscribe to our newsletter',
      placeholder: 'Enter email address',
      isSubscribe: true,
      image: {
        title: 'newsletter',
        fluid: {
          src: newsletterSVG,
        },
      },
    },
  }

  state = {
    email_address: '',
    isLoading: false,
    isSubmitted: false,
    error: '',
    errorColor: '',
  }

  handleEmailInput = e => this.setState({ email_address: e.target.value })

  handleSubmit = e => {
    e.preventDefault()

    const { email_address } = this.state

    if (!checkEmailValid(email_address)) {
      this.setState({
        error: 'Please enter a valid email address',
        errorColor: 'red',
      })
      return
    }

    this.setState({ isLoading: true })
    axios
      .post(`${process.env.GATSBY_LAMBDA_API_URL}/subscribe`, { email_address })
      .then(() => {
        setTimeout(() => {
          this.setState({ isSubmitted: true, isLoading: false })
        }, 1000)
        gtmEvent(
          'Blog Subscription',
          'subscribe',
          'Blog Subscribe',
          window.location.href
        )
      })
      .catch(e => {
        this.setState({ isLoading: false })
        const errorMessage = e.response.data.title
        if (errorMessage === 'Member Exists') {
          this.setState({
            error: 'You are already subscribed to our mailing list',
            errorColor: 'green',
          })
        } else if (errorMessage === 'Invalid Resource') {
          this.setState({
            error: 'Please enter a valid email address',
            errorColor: 'red',
          })
        } else {
          this.setState({
            error: 'Whoops something went wrong on our end',
            errorColor: 'red',
          })
        }
      })
  }

  render() {
    const { isLoading, isSubmitted, error, errorColor } = this.state
    let {
      title,
      image,
      buttonText,
      placeholder,
      isSubscribe,
      url,
    } = this.props.cta

    /**
     * TODO: Refactor the logic below to handle this dynamically.
     * Currently gatsby-source-contentful doesn't return set node to empty
     * for reference field type if any of the content type is not referenced
     * in Blog Post Model for CTA
     */

    if (title === 'Placeholder') {
      //Defaults if fields not filled in
      title = 'Get the latest updates from Crowdlinker'
      image = {
        title: 'newsletter',
        fluid: {
          src: newsletterSVG,
        },
      }
      buttonText = 'Subscribe to our newsletter'
      placeholder = 'Enter email address'
      url = 'https://crowdlinker.com/'
      isSubscribe = true
    }

    const renderSubscribeOrCTA = isSubscribe ? (
      <BlogCTAForm onSubmit={this.handleSubmit}>
        <InputWrapper>
          <EmailInput
            type="text"
            placeholder={placeholder}
            onChange={this.handleEmailInput}
          />
          <Underline />
        </InputWrapper>
        <MobileErrorMessage errorColor={errorColor} error={!!error}>
          {error}
        </MobileErrorMessage>
        <BlogSubscribeButton
          height="40px"
          minWidth="266px"
          fontSize="16px"
          gradientTop="1px"
          gradientHeight="calc(100% - 2px)"
          gradientWidth="calc(100% - 4px)"
          gradientRadius="30.5px"
          mobileFullWidth={true}
        >
          <BlogButtonInner>{buttonText}</BlogButtonInner>
        </BlogSubscribeButton>
        {error && <ErrorMessage errorColor={errorColor}>{error}</ErrorMessage>}
        {isLoading && (
          <LoadingContainer>
            <DotLoading />
          </LoadingContainer>
        )}
      </BlogCTAForm>
    ) : (
      <BlogCTAButton
        href={url}
        height="40px"
        minWidth="100%"
        fontSize="16px"
        marginTop="30px"
        gradientTop="1px"
        gradientHeight="calc(100% - 2px)"
        gradientWidth="calc(100% - 4px)"
        gradientRadius="30.5px"
        mobileFullWidth={true}
      >
        <BlogButtonInner>{buttonText}</BlogButtonInner>
      </BlogCTAButton>
    )

    return (
      <Container>
        {isLoading && <OpacityOverlay />}
        <BlogCTAWrapper>
          <BlogCTAImage src={image.fluid.src} alt={image.title} />
          <BlogCTAContainer>
            {isSubmitted ? (
              <BlogCTABox>
                <SuccessHeader>Check your email!</SuccessHeader>
                <SuccessBody>
                  You should recieve an email from us with a verfication link
                  very shortly.
                </SuccessBody>
              </BlogCTABox>
            ) : (
              <BlogCTABox>
                <Title>{title}</Title>
                {renderSubscribeOrCTA}
              </BlogCTABox>
            )}
          </BlogCTAContainer>
        </BlogCTAWrapper>
      </Container>
    )
  }
}

export default BlogCTA
