import React from 'react'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import DevelopmentImage from '../../../images/technology-bg-desktop.png'
import DevelopmentImageMobile from '../../../images/technology-bg-tablet.png'
import Background from './Background'
import BackgroundImage from '../../../images/tech-grid-gradient.svg'
import ReactImage from '../../../images/techstack/react.svg'
import VanillaJS from '../../../images/techstack/js.svg'
import AWS from '../../../images/techstack/aws.svg'
import NodeJSLogo from '../../../images/techstack/node.svg'
import WordPressLogo from '../../../images/techstack/wordpress.svg'
import SwiftLogo from '../../../images/techstack/swift.svg'
import MySQLLogo from '../../../images/techstack/mysql.svg'
import LaravelLogo from '../../../images/techstack/laravel.svg'
import DockerLogo from '../../../images/techstack/docker.svg'
import PHPLogo from '../../../images/techstack/php.svg'
import KubernetesLogo from '../../../images/techstack/kubernetes.svg'
import PythonLogo from '../../../images/techstack/python.svg'
import GoogleCloudLogo from '../../../images/techstack/gcp.svg'
import ScrollableAnchor from 'react-scrollable-anchor'

import {
  Title,
  ServiceHeadingMedium,
  ServiceHeadingSmall,
  LargeParagraph,
} from '../../Text'

import {
  TwoColumn as TwoColumnBase,
  DevelopmentImageSection,
  TextSection as TextSectionBase,
  ServicesList,
  ServiceItem,
  ImageWrapper,
  FadeSectionOne,
  FadeSectionTwo,
} from './Layout'
import { anchors } from '../../../constants'

const TwoColumn = styled(TwoColumnBase)`
  @media (max-width: 1280px) {
    display: block;
    max-width: none;
  }
`

const TextSection = styled(TextSectionBase)`
  @media (max-width: 1280px) {
    max-width: 700px;
    margin: auto;
  }
`

const services = [
  'Modern JavaScript',
  'Web & Mobile Applications',
  'DevOps',
  'Agile Development',
  'APIs & Integrations',
  'QA, Test & Delivery Automation',
  'Cloud Architecture & Migration',
  'Content Management Systems',
  'Technical Direction',
  'Rapid Prototyping',
]

const techMap = [
  {
    img: ReactImage,
    alt: 'Logo image for React',
  },
  {
    img: VanillaJS,
    alt: 'Logo image for Javascript',
  },
  {
    img: AWS,
    alt: 'Logo image for AWS',
  },
  {
    img: NodeJSLogo,
    alt: 'Logo image for Nodejs',
  },
  {
    img: PythonLogo,
    alt: 'Logo image for Python',
  },
  {
    img: PHPLogo,
    alt: 'Logo image for PHP',
  },
  {
    img: MySQLLogo,
    alt: 'Logo image for MySQL',
  },
  {
    img: LaravelLogo,
    alt: 'Logo image for Laravel',
  },
  {
    img: WordPressLogo,
    alt: 'Logo image for Wordpress',
  },
  {
    img: SwiftLogo,
    alt: 'Logo image for Swift',
  },
  {
    img: DockerLogo,
    alt: 'Logo image for Docker',
  },
  {
    img: KubernetesLogo,
    alt: 'Logo image for Kubernetes',
  },
  {
    img: GoogleCloudLogo,
    alt: 'Logo image for Google Cloud Platform',
  },
]

const TechStackContainer = styled('div')`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: -125px;

  @media (max-width: 1440px) {
    margin-top: -40px;
  }

  @media (max-width: 1280px) {
    margin-top: 50px;
    max-width: 530px;
  }

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    width: 100%;
    margin-top: 50px;
  }
`

const TechStackImage = styled('img')`
  max-width: 100%;
  margin: 14px;

  @media (max-width: ${props => props.theme.screenWidth.medium}) {
    margin: 12px;
  }
`

const primaryColor = '#73acd8'
const textColor = '#fff'

const Development = () => (
  <ScrollableAnchor id={anchors.engineering}>
    <Background
      background={'url(' + BackgroundImage + '), #141b21'}
      mobileBackground={'#141b21'}
    >
      <Title color={primaryColor}>engineering</Title>
      <TwoColumn>
        <DevelopmentImageSection>
          <picture>
            <source
              srcSet={`${DevelopmentImageMobile}`}
              media="(max-width: 1280px)"
            />
            <ImageWrapper src={DevelopmentImage} alt="" />
          </picture>
        </DevelopmentImageSection>
        <TextSection>
          <FadeSectionOne>
            <ServiceHeadingMedium color={textColor}>
              Bringing your product to life with a great software build
            </ServiceHeadingMedium>
            <LargeParagraph color={textColor}>
              Great user experiences require the technology powering them to be
              considered throughout the product life-cycle. We work closely with
              your team to ensure there is clarity, consistency, and effective
              technical implementation that follows proven methodology. By
              adhering to the agile development practice and leveraging our
              senior expertise across multiple tech stacks we ensure that your
              product is scalable.
            </LargeParagraph>
          </FadeSectionOne>
          <FadeSectionTwo>
            <ServiceHeadingSmall color={primaryColor}>
              services
            </ServiceHeadingSmall>
            <ServicesList>
              {services.map((item, index) => {
                return (
                  <ServiceItem key={index} color={textColor}>
                    {item}
                  </ServiceItem>
                )
              })}
            </ServicesList>
          </FadeSectionTwo>
        </TextSection>
      </TwoColumn>
      <Fade delay={800}>
        <TechStackContainer>
          {techMap.map((item, index) => (
            <TechStackImage src={item.img} alt={item.alt} key={index} />
          ))}
        </TechStackContainer>
      </Fade>
    </Background>
  </ScrollableAnchor>
)

export default Development
