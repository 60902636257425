import styled from '@emotion/styled'
import React from 'react'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import CircleSVG from '../../images/circle-solid.svg'

const InlineParagraph = styled('p')`
  font-size: 22px;
  line-height: 1.27;
  color: #323232;
  font-family: ${props => props.theme.fonts.secondary};
  margin: 0 0 30px;

  a {
    transition: color 0.3s ease;
    color: #000;

    &:hover {
      color: #747474;
      text-decoration-color: #747474;
    }
  }
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

const H1 = styled('h1')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 22px;
  font-weight: 500;
  line-height: 1.18;
  color: #000000;
  margin: 0 0 9px;
`

const H2 = styled('h2')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 20px;
  font-weight: 500;
  color: #000000;
  line-height: 1.18;
  margin: 0 0 9px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 17px;
  }
`

const H3 = styled('h3')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 22px;
  font-weight: bold;
  color: #323232;
  line-height: 1.18;
  margin: 0 0 9px;
`

const H4 = styled('h4')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 13px;
  letter-spacing: 0.8px;
  line-height: 1.35;
  color: #747474;
`

const H5 = styled('h5')`
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  font-family: ${props => props.theme.fonts.secondary};
`

const H6 = styled('h6')`
  font-size: 22px;
  line-height: 1.27;
  font-weight: 300;
  margin-bottom: 5px;
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

const Quote = styled('blockquote')`
  position: relative;
  margin: 0 0 25px;
  padding-left: 48px;
  padding-top: 25px;
  padding-bottom: 10px;

  &:before {
    content: '';
    width: 6px;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(193, 28, 92, 0.9);
  }
  p {
    font-family: ${props => props.theme.fonts.primaryMedium};
    font-size: 24px;
    font-weight: 500;
    line-height: 1.71;
    letter-spacing: 1px;
    color: #000000;
    margin: 0 0 20px;
    @media (max-width: ${props => props.theme.screenWidth.small}) {
      font-size: 20px;
    }
  }
`

const BlockImageWithCaption = styled('figure')`
  width: 100%;
  margin: 0 0 40px;
  padding: 0;
`

const BlockImage = styled('img')`
  width: 100%;
  height: auto;
  margin: 0 0 4px;
`

const Caption = styled('figcaption')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 16px;
  font-style: italic;
  line-height: 1.13;
  text-align: center;
  color: #323232;
  max-width: 650px;
  margin: 0 auto;
`

const List = styled('ul')`
  margin: 0 0 30px;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 22px;
  line-height: 1.27;
  color: #323232;
  list-style: none;
  padding: 0;

  li {
    list-style: none;
    display: flex;

    &:before {
      content: '';
      background: url(${CircleSVG}) no-repeat left top;
      background-size: 4px;
      padding-right: 25px;
      margin-top: 10px;
    }
  }
`

const OrderedList = styled('ol')`
  margin: 0 0 30px;
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 22px;
  line-height: 1.27;
  color: #323232;
  padding-left: 20px;

  li {
    padding-left: 14px;
  }
`

const ListItem = styled('li')`
  p {
    margin: 0;
  }
`

const InlineImageContainer = styled('div')`
  width: ${props => (props.width ? props.width : '50%')};
  float: ${props => props.align};
  padding: ${props => (props.align === 'left' ? '0 20px 0 0' : '0 0 0 20px')};
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
    padding: 0;
  }
`

const InlineImage = styled('img')`
  width: 100%;
`

const BoldText = styled('span')`
  font-weight: bold;
`

const Spacer = styled('div')`
  height: ${props => props.height};
  width: 100%;
`

const IntrinsicContainer = styled('div')`
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  margin-bottom: 30px;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const Bold = ({ children }) => <BoldText>{children}</BoldText>
const Text = ({ children }) => <InlineParagraph>{children}</InlineParagraph>

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [BLOCKS.EMBEDDED_ASSET]: node => {
      if (node.data.target.fields) {
        const { description, file } = node.data.target.fields
        const desc = description ? description['en-US'] : ''

        return (
          <BlockImageWithCaption>
            <BlockImage alt={desc} src={file['en-US'].url} />
            {desc && <Caption>{desc}</Caption>}
          </BlockImageWithCaption>
        )
      }
    },
    [BLOCKS.EMBEDDED_ENTRY]: node => {
      if (node.data.target.fields) {
        const contentType = node.data.target.sys.contentType.sys.id

        if (contentType === 'blogInlineImage') {
          const inlineImageFields = node.data.target.fields
          const align = inlineImageFields.align['en-US']
          const width = inlineImageFields.width
            ? inlineImageFields.width['en-US']
            : ''
          const title = inlineImageFields.title['en-US']
          const url = inlineImageFields.image['en-US'].fields.file['en-US'].url
          const showCaption = inlineImageFields.showCaption['en-US']

          return (
            <InlineImageContainer width={width} align={align}>
              <InlineImage src={url} alt={title} />
              {showCaption && <Caption>{title}</Caption>}
            </InlineImageContainer>
          )
        }
        // 'test' is Social Media Link. Currently there is no way on contentful to change field's ID except to remove and remake it.
        if (contentType === 'test') {
          const { url, alignment, maxWidth } = node.data.target.fields
          let dataCardWidth = '500px'
          if (maxWidth && maxWidth['en-US']) {
            dataCardWidth = maxWidth['en-US']
          }

          const align = alignment ? alignment['en-US'] : 'center'
          return (
            <a
              href={url['en-US']}
              className="embedly-card"
              data-card-align={align}
              data-card-controls="0"
              data-card-width={dataCardWidth}
              data-card-key={process.env.GATSBY_EMBEDLY_API_KEY}
            >
              &nbsp;
            </a>
          )
        }
        if (contentType === 'blogSpacer') {
          const height = node.data.target.fields.amount['en-US']
          return <Spacer height={height} />
        }

        /**
         * YouTube Video Content Type
         */
        if (contentType === 'youTubeVideo') {
          const { youTubeUrl } = node.data.target.fields

          return (
            <IntrinsicContainer>
              <iframe
                src={youTubeUrl['en-US']}
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </IntrinsicContainer>
          )
        }
        return
      }
    },
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_2]: (node, children) => <H2>{children}</H2>,
    [BLOCKS.HEADING_3]: (node, children) => <H3>{children}</H3>,
    [BLOCKS.HEADING_4]: (node, children) => <H4>{children}</H4>,
    [BLOCKS.HEADING_5]: (node, children) => <H5>{children}</H5>,
    [BLOCKS.HEADING_6]: (node, children) => <H6>{children}</H6>,
    [BLOCKS.OL_LIST]: (node, children) => <OrderedList>{children}</OrderedList>,
    [BLOCKS.UL_LIST]: (node, children) => <List>{children}</List>,
    [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data
      const isExternalLink = !/http:\/\/(www\.)?crowdlinker\.com/i.test(uri)
      return (
        <a
          href={uri}
          {...(isExternalLink && {
            target: '_blank',
            rel: 'noopener noreferrer',
          })}
        >
          {children}
        </a>
      )
    },
  },
}

const PostContent = props => documentToReactComponents(props.content, options)

export default PostContent
