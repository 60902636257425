import React from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import Fade from 'react-reveal/Fade'
import linkImage from '../../images/see-all-link.svg'
import Img from 'gatsby-image'

const Section = styled('div')`
  padding: 110px 0;
  width: 100%;
  background: #fff;

  @media (max-width: 1280px) {
    padding: 89px 0;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 90px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-bottom: 40px;
  }
`

const Wrapper = styled('div')`
  padding: 0 74px;
  max-width: 1348px;
  margin: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 768px) {
    padding: 0 30px;
  }
`

const HeadingTitle = styled('h2')`
  font-size: 54px;
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-weight: 500;
  margin: 0 auto 12px;
  line-height: 1.25;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 35px;
  }
`
const HeadingDescription = styled('h3')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 38px;
  font-weight: 100;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.21;
  letter-spacing: normal;
  text-align: center;
  color: #000000;
  margin: 0 auto 64px;
  max-width: 580px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 0 auto 50px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    max-width: 264px;
    margin: 0 auto 30px;
  }
`
const BlogsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  margin: 0 0 55px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    align-items: center;
    margin: 0;
  }
`

const BlogCardLink = styled(Link)`
  outline: none;
  text-decoration: none;
  display: block;
  width: 100%;
  max-width: 241px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 100%;
  }
`

const BlogCard = styled('div')`
  display: flex;
  align-items: center;
  flex-basis: 50%;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
    margin: 0 0 25px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin-bottom: 40px;
    width: 100%;
    flex-direction: column;
  }
`
const BlogImage = styled(Img)`
  max-width: 241px;
  width: 100%;
  height: auto;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 100%;
    margin: 0 0 16px;
  }
`
const BlogText = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 310px;
  text-align: left;
  padding: 0 24px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    width: 100%;
    max-width: 450px;
    padding-left: 61px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 0;
    max-width: 100%;
  }

  a {
    color: #000;
    text-decoration: none;
  }
`
const BlogCategory = styled('p')`
  font-family: ${props => props.theme.fonts.sourceCode};
  text-transform: uppercase;
  margin: 0 0 8px;
  font-size: 13px;
  letter-spacing: 0.81px;
  color: #747474;
`

const BlogTitle = styled('span')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  margin: 0;
  font-size: 22px;
  font-weight: 500;
  line-height: 1.3;
  color: #000000;
  background-image: linear-gradient(#000, #000);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 2px;
  transition: background-size 0.3s;
  &:hover,
  &:focus {
    background-size: 100% 2px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
  }
`

const AllBlogPostsLinkWrapper = styled('div')`
  width: 100%;
  text-align: center;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    text-align: left;
  }
`

const AllBlogPostsLink = styled(Link)`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: rgba(193, 28, 92, 0.9);
  position: relative;
  padding-right: 60px;
  margin: 0 auto;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0;
    font-size: 15px;
    padding-right: 50px;
  }

  &::after {
    background-image: url(${linkImage});
    background-repeat: no-repeat;
    background-size: 37px;
    background-position: center;
    content: '';
    position: absolute;
    right: 0;
    top: 0;
    width: 37px;
    height: 35px;
    z-index: 1;

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      height: 21px;
    }
  }
`

export default () => (
  <StaticQuery
    query={graphql`
      {
        contentfulHomeBlogPosts {
          blogs {
            title
            id
            slug
            category {
              title
            }
            image {
              sizes(maxWidth: 564, maxHeight: 377, cropFocus: CENTER) {
                ...GatsbyContentfulSizes
              }
            }
          }
        }
      }
    `}
    render={data => {
      const blogPosts = data.contentfulHomeBlogPosts.blogs

      const blogCards = blogPosts.map(post => (
        <BlogCard key={post.id}>
          <BlogCardLink to={`/blog/` + post.slug}>
            <BlogImage sizes={post.image.sizes} />
          </BlogCardLink>
          <BlogText>
            <BlogCategory>{post.category.title}</BlogCategory>
            <Link to={`/blog/` + post.slug}>
              <BlogTitle>{post.title}</BlogTitle>
            </Link>
          </BlogText>
        </BlogCard>
      ))

      return (
        <Section>
          <Wrapper>
            <HeadingTitle>Latest posts</HeadingTitle>
            <HeadingDescription>
              Enhance your knowledge of digital products with expert advice from
              our team.
            </HeadingDescription>
            <Fade bottom distance={'100px'}>
              <BlogsContainer>{blogCards}</BlogsContainer>
              <AllBlogPostsLinkWrapper>
                <AllBlogPostsLink to="/blog">
                  See more posts on our blog
                </AllBlogPostsLink>
              </AllBlogPostsLinkWrapper>
            </Fade>
          </Wrapper>
        </Section>
      )
    }}
  />
)
