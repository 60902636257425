import React from 'react'
import ScrollableAnchor from 'react-scrollable-anchor'
import StrategyImage from '../../../images/strategy-bg-desktop.svg'
import StrategyImageMobile from '../../../images/strategy-bg-tablet.svg'
import Background from './Background'

import {
  Title,
  ServiceHeadingMedium,
  ServiceHeadingSmall,
  LargeParagraph,
} from '../../Text'

import {
  TwoColumn,
  StrategyImageSection,
  TextSection,
  ServicesList,
  ServiceItem,
  ImageWrapper,
  FadeSectionOne,
  FadeSectionTwo,
} from './Layout'
import { anchors } from '../../../constants'

const services = [
  'Digital Strategy',
  'Opportunity Identification',
  'Growth Opportunities',
  'Stakeholder Sessions',
  'Brand & Business Positioning',
  'User Personas',
  'Customer Journey Mapping',
  'Product Validation',
  'Experience Mapping',
  'Usability Testing',
]

const Strategy = () => (
  <ScrollableAnchor id={anchors.strategy}>
    <Background>
      <Title>strategy</Title>

      <TwoColumn>
        <StrategyImageSection>
          <picture>
            <source
              srcSet={`${StrategyImageMobile}`}
              media="(max-width: 1280px)"
            />
            <ImageWrapper src={StrategyImage} alt="" />
          </picture>
        </StrategyImageSection>

        <TextSection>
          <FadeSectionOne>
            <ServiceHeadingMedium>
              Every project starts with the discovery phase.
            </ServiceHeadingMedium>
            <LargeParagraph>
              Through discovery sessions, we align ourselves with your
              organization and find out your business objectives, product needs,
              and success criteria. We break down problems into smaller pieces
              and uncover the most effective solution for your business.
            </LargeParagraph>
          </FadeSectionOne>
          <FadeSectionTwo>
            <ServiceHeadingSmall>services</ServiceHeadingSmall>
            <ServicesList>
              {services.map((item, index) => {
                return <ServiceItem key={index}>{item}</ServiceItem>
              })}
            </ServicesList>
          </FadeSectionTwo>
        </TextSection>
      </TwoColumn>
    </Background>
  </ScrollableAnchor>
)

export default Strategy
