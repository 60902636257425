import React from 'react'
import styled from '@emotion/styled'
import TopHatLogo from '../../images/clients/tophat-logo.svg'
import FreshBooksLogo from '../../images/clients/freshbooks-logo.svg'
import LeagueLogo from '../../images/clients/league-logo.svg'
import NescafeLogo from '../../images/clients/nescafe-logo-white.svg'
import MLSELogo from '../../images/clients/mlse-svg.svg'
import FinaeoLogo from '../../images/clients/finaeo-logo-white.svg'
import ParityLogo from '../../images/clients/parity-logo-white.svg'
import UnileverLogo from '../../images/clients/vaseline-logo-copy.svg'
import DealTapLogo from '../../images/clients/dealtap-copy.svg'
import 'swiper/swiper.scss'
import Swiper from 'react-id-swiper'

const ClientCarouselWrapper = styled('div')`
  padding-top: 40px;
  padding-left: 10px;

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 130px;
    margin: auto;
    padding-left: 0;
  }
`

const ClientLogoWrapper = styled('div')``

const ClientLogo = styled('img')`
  max-width: 180px;
  height: 90px;

  @media only screen and (max-device-width: 850px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: landscape) {
    max-width: 130px;
    height: 60px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: 130px;
    height: 60px;
  }
`

const clientsMap = [
  { src: MLSELogo, alt: 'Nudge Rewards' },
  { src: TopHatLogo, alt: 'Top Hat' },
  { src: FreshBooksLogo, alt: 'Freshbooks' },
  { src: LeagueLogo, alt: 'League', 'max-width': '96px' },
  { src: UnileverLogo, alt: 'Unilever' },
  { src: NescafeLogo, alt: 'Nescafe' },
  { src: FinaeoLogo, alt: 'Finaeo' },
  { src: DealTapLogo, alt: 'DealTap' },
  { src: ParityLogo, alt: 'Parity' },
]

const params = {
  slidesPerView: 1,
  spaceBetween: 1, // need 1px to fix bug in safari
  autoHeight: true,
  speed: 200,
  direction: 'vertical',
  height: 90,
  autoplay: {
    delay: 800,
    disableOnInteraction: false,
  },
  noSwiping: true,
}

const Clients = () => (
  <ClientCarouselWrapper>
    <Swiper {...params}>
      {clientsMap.map(({ alt, src, ...rest }, index) => (
        <ClientLogoWrapper>
          <ClientLogo key={index} src={src} alt={alt} {...rest} />
        </ClientLogoWrapper>
      ))}
    </Swiper>
  </ClientCarouselWrapper>
)

export default Clients
